import { BaseIconPathStyle, BaseSvgStyle } from '../IconBaseStyle';
import { ComponentProps } from '../SVGBase';

function Shipping({ size, color, title, ariaHidden }: ComponentProps) {
  return (
    <BaseSvgStyle
      size={size}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden={ariaHidden}
    >
      {title && <title>{title}</title>}
      <BaseIconPathStyle
        d="M0.766113 13.2219L3.70311 14.7089"
        stroke={color}
        strokeRound
      />
      <BaseIconPathStyle
        d="M0.860352 9.23401L3.67235 10.74"
        stroke={color}
        strokeRound
      />
      <BaseIconPathStyle
        d="M2.04492 5.91797L3.73392 6.95897"
        stroke={color}
        strokeRound
      />
      <BaseIconPathStyle
        d="M10.8589 5.151L19.1089 9.366"
        stroke={color}
        strokeRound
      />
      <BaseIconPathStyle
        d="M14.0619 3.51395L7.87391 6.67595C7.52891 6.85492 7.23995 7.12557 7.03882 7.45814C6.8377 7.7907 6.73219 8.1723 6.73391 8.56095V15.44C6.73228 15.8287 6.83794 16.2104 7.03925 16.543C7.24056 16.8755 7.52972 17.1461 7.87491 17.325L14.0619 20.486C14.3474 20.6323 14.6636 20.7087 14.9844 20.7087C15.3052 20.7087 15.6214 20.6323 15.9069 20.486L22.0939 17.325C22.439 17.1461 22.728 16.8754 22.9291 16.5429C23.1303 16.2103 23.2357 15.8286 23.2339 15.44V8.56095C23.2358 8.17226 23.1304 7.79057 22.9293 7.45796C22.7281 7.12535 22.4391 6.85474 22.0939 6.67595L15.9069 3.51395C15.6213 3.36806 15.3051 3.29199 14.9844 3.29199C14.6637 3.29199 14.3475 3.36806 14.0619 3.51395V3.51395Z"
        stroke={color}
        strokeRound
      />
      <BaseIconPathStyle
        d="M22.908 7.42493L14.984 11.4729L7.06104 7.42493"
        stroke={color}
        strokeRound
      />
      <BaseIconPathStyle
        d="M14.9839 11.4729V20.7089"
        stroke={color}
        strokeRound
      />
      <BaseIconPathStyle
        d="M19.1089 9.36597V12.278"
        stroke={color}
        strokeRound
      />
    </BaseSvgStyle>
  );
}

export default Shipping;
